import React from 'react';
import { Link } from 'gatsby';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;

const PrivacyPolicy = () => {


  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-samochodow'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing samochodów osobowych i dostawczych'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing samochodów osobowych i dostawczych</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
              </div>

          </HandleLoading>
          {/* <div className={s_text.double}>
            <div>
              <p>
                Leasing samochodów osobowych, dostawczych i ciężarowych to jedna z najpopularniejszych form finansowania
                w każdej firmie. Niesie za sobą mnóstwo korzyści, jest niesamowicie bezpieczna i elastyczna. Na rynku
                jest mnóstwo firm leasingowych, które chętnie sfinansują Twoją „osobówkę”. Nieważny kolor, marka, stan
                licznika…Mając szeroki wachlarz możliwości do wyboru, jesteśmy w stanie spełnić każde oczekiwanie, marzenie,
                zachciankę…Wybór należy do Ciebie. Nowa Toyota, Mazda, Renault a może? Dla Ciebie świetny środek komunikacyjny,
                dla Twojej firmy niezbędne koszty, które jesteś w stanie odliczyć od podatku. Używane BMW X6, Mercedes GLC?
                Świetny wybór, wygodny, przestronny, reprezentacyjny. Koszt dla firmy, wygoda dla Ciebie.
              </p>
              <p>
                Pamiętaj masz wybór! Wybierz mądrze!
              </p>
            </div>
            <div>
              <p>
                Jesteśmy firmą oferującą:
              </p>
              <ul className={s_text.checkmark_list}>
                <li><Link to='/oferta/leasing-samochodow-osobowych'>Leasing samochodów osobowych</Link></li>
                <li><Link to='/oferta/leasing-samochodow-dostawczych'>Leasing samochodów dostawczych do 3,5T</Link></li>
                <li><Link to='/oferta/leasing-samochodow-ciezarowych-ciagnikow-naczep'>Leasing samochodów ciężarowych</Link></li>
                <li><Link to='/oferta/leasing-samochodow-nowych-i-uzywanych'>Leasing samochodów nowych i używanych</Link></li>
                <li><Link to='/oferta/leasing-samochodow-sprowadzonych'>Leasing samochodów sprowadzonych</Link></li>
              </ul>
              <p>
                Mamy 40 firm leasingowych, spośród których dla naszych klientów wybieramy najlepsze oferty.
              </p>
            </div>
          </div> */}


        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
